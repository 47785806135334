import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import HeroHeader from "../components/header/heroHeader"
import TextImageRight from "../components/common/textWithRightImage"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Modal from "../components/common/modal"
import SubscriptionForm from "../components/forms/subscriptionForm"

const Strumentazione = ({ data }) => {

  const { t } = useTranslation();
  return (
  <Layout>
    <Seo
      title={t("strumentazione.seoTitle")}
      description={t("strumentazione.seoDescription")}
      seoImage={data.plant_produttivo.childImageSharp.resize.src}
    />
    <Modal
        component={<SubscriptionForm />}
    /> 
    <HeroHeader
      fluid={data.plant_produttivo.childImageSharp.fluid}
      headerTitle={t("strumentazione.headerTitle")}
      headerSubtitle={t("strumentazione.headerSubtitle")}
      alt={t("strumentazione.heroImageTag")}
    />
    <div className="section">
      <div className="container">
        <TextImageRight
          titleOne={t("strumentazione.titleOne")}
          textOne={t("strumentazione.textOne")}
          titleTwo={t("strumentazione.titleTwo")}
          textTwo={t("strumentazione.textTwo")}
          fixed={data.cablaggio.childImageSharp.fixed}
          alt={t("strumentazione.textImageTag")}
        />
      </div>
    </div>
  </Layout>
  )
}

export default Strumentazione

export const StrumentiEMacchinariQuery = graphql`
  query {
    plant_produttivo: file(relativePath: { eq: "Plant_Produttivo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    cablaggio_industriale: file(
      relativePath: { eq: "assemblaggio_cablaggio_industriale.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cablaggio: file(
      relativePath: { eq: "assemblaggio_cablaggio_industriale.jpg" }
    ) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
